<template>
  <div class="relative">
    <select
      class="focus:outline-none block w-full cursor-pointer rounded-md border py-1 px-2 text-slate-500 shadow-sm"
      :class="
        invalid
          ? 'border-2 border-rose-600 focus:border-rose-500 focus:ring focus:ring-rose-200'
          : 'border border-slate-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200'
      "
      :id="id"
      @keydown.enter.space.prevent="toggleOptions()"
      @mousedown.prevent="
        (event) => {
          event.target.focus();
          toggleOptions();
        }
      "
      @touchstart.prevent="
        (event) => {
          event.target.focus();
          toggleOptions();
        }
      "
      @focus="focus = true"
      @focusout="focus = false"
    >
      <option v-if="modelValue" selected>
        {{
          translatableLabel
            ? t(`${translatableLabel}.${getLabel(modelValue)}`)
            : getLabel(modelValue)
        }}
      </option>
    </select>
    <div
      v-if="showOptions"
      class="absolute top-full left-0 z-20 w-full rounded-md border border-slate-300 bg-white"
    >
      <div class="max-h-60 overflow-y-scroll">
        <div
          v-for="option in options"
          :key="option.value || option.id"
          class="flex cursor-pointer items-center p-3 hover:bg-indigo-200"
          :class="
            modelValue &&
            (modelValue == option.value || modelValue == option.id)
              ? 'bg-indigo-100'
              : null
          "
          @click="select(option)"
        >
          {{
            translatableLabel
              ? t(`${translatableLabel}.${getLabel(option)}`)
              : getLabel(option)
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
export default {
  props: {
    id: {
      required: true,
      type: String,
    },
    invalid: {
      required: true,
      type: Boolean,
    },
    modelValue: {
      type: [Number, String],
    },
    optionLabel: {
      required: true,
      type: Array[String],
    },
    options: {
      required: true,
      type: Array[Object],
    },
    translatableLabel: {
      required: false,
      type: String,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const store = useStore();
    const { t } = useI18n();

    const focus = ref(false);

    const showOptions = ref(false);

    const toggleOptions = () => {
      showOptions.value = !showOptions.value;
    };

    const getLabel = (option) => {
      if (!option) return;
      if (!option.value && !option.id) {
        option = props.options.find(
          (opt) => opt.value == option || opt.id == option
        );
      }
      let parts = [];
      for (const key of props.optionLabel) {
        parts.push(option[key]);
      }
      return parts.join(" ");
    };

    const select = (option) => {
      emit("update:modelValue", option.value ? option.value : option.id);
      showOptions.value = false;
    };

    return {
      company: computed(() => store.state.company.company),
      focus,
      getLabel,
      select,
      showOptions,
      t,
      toggleOptions,
      waiting: computed(() => store.state.company.waiting),
    };
  },
};
</script>
