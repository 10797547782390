<template>
  <div class="relative">
    <select
      class="focus:outline-none block w-full cursor-pointer rounded-md border py-1 px-2 text-slate-500 shadow-sm"
      :class="
        invalid
          ? 'border-2 border-rose-600 focus:border-rose-500 focus:ring focus:ring-rose-200'
          : 'border border-slate-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200'
      "
      :id="id"
      readonly
      @keydown.enter.space.prevent="toggleOptions()"
      @mousedown.prevent="
        (event) => {
          event.target.focus();
          toggleOptions();
        }
      "
      @touchstart.prevent="
        (event) => {
          event.target.focus();
          toggleOptions();
        }
      "
    />
    <div
      class="pointer-events-none absolute top-0 left-0 flex h-full w-full items-center gap-1 overflow-hidden px-2 py-1"
    >
      <div
        v-for="item in modelValue"
        :key="item"
        class="rounded-md bg-slate-300 px-2"
      >
        {{
          translatableLabel
            ? t(`${translatableLabel}.${getLabel(item)}`)
            : getLabel(item)
        }}
      </div>
    </div>
    <div
      v-if="showOptions"
      class="absolute top-full left-0 z-20 w-full rounded-md border border-slate-300 bg-white"
    >
      <div
        class="flex items-center justify-between border-b border-slate-300 p-3"
      >
        <form-checkbox
          id="checkbox-select-all"
          :invalid="false"
          variant="indigo"
          v-model="selectedAll"
        />
        <button
          class="rounded-full p-1 hover:bg-slate-300"
          type="button"
          @click="toggleOptions()"
        >
          <XMarkIcon class="h-5 w-5" />
        </button>
      </div>
      <div class="max-h-60 overflow-y-scroll">
        <div
          v-for="option in options"
          :key="option.value || option.id"
          class="flex items-center p-3"
          :class="
            selected.includes(option.value || option.id) ? 'bg-indigo-100' : ''
          "
        >
          <form-checkbox
            class="mr-3"
            :id="option.value || option.id"
            :invalid="false"
            :value="option.value || option.id"
            v-model="selected"
            variant="indigo"
          />{{
            translatableLabel
              ? t(`${translatableLabel}.${getLabel(option)}`)
              : getLabel(option)
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import FormCheckbox from "./FormCheckbox.vue";
export default {
  components: {
    FormCheckbox,
    XMarkIcon,
  },
  props: {
    id: {
      required: true,
      type: String,
    },
    invalid: {
      required: true,
      type: Boolean,
    },
    modelValue: {
      required: true,
      type: Array[String],
    },
    optionLabel: {
      required: true,
      type: Array[String],
    },
    options: {
      required: true,
      type: Array[Object],
    },
    translatableLabel: {
      required: false,
      type: String,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const showOptions = ref(false);

    const toggleOptions = () => {
      showOptions.value = !showOptions.value;
    };

    const getLabel = (option) => {
      if (!option) return;
      if (!option.value && !option.id) {
        option = props.options.find(
          (opt) => opt.value == option || opt.id == option
        );
      }
      let parts = [];
      for (const key of props.optionLabel) {
        parts.push(option[key]);
      }
      return parts.join(" ");
    };

    const getLabels = (values) => {
      let labels = [];
      for (const value of values) {
        const option = props.options.find(
          (option) => option.id == value || option.value == value
        );
        if (!option) continue;
        let label = getLabel(option);
        if (props.translatableLabel) {
          label = t(`${props.translatableLabel}.${label}`);
        }
        labels.push(label);
      }
      return labels.join(", ");
    };

    const selected = ref([...props.modelValue]);

    const selectedAll = ref(false);

    const toggleSelectAll = () => {
      if (selectedAll.value) {
        selected.value = props.options.map(
          (option) => option.value || option.id
        );
      } else if (selected.value.length == props.options.length) {
        selected.value = [];
      }
    };

    watch(
      () => selectedAll.value,
      () => toggleSelectAll()
    );

    watch(
      () => selected.value,
      (selected) => {
        selectedAll.value = selected.length == props.options.length;
        emit("update:modelValue", selected);
      }
    );

    return {
      emit,
      getLabel,
      getLabels,
      showOptions,
      selected,
      selectedAll,
      t,
      toggleOptions,
      toggleSelectAll,
    };
  },
};
</script>
