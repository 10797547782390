<template>
  <input
    class="h-6 w-6 cursor-pointer rounded-md border"
    :class="
      invalid
        ? `border-2 border-rose-600 text-rose-500 focus:border-rose-500 focus:ring focus:ring-rose-200`
        : `text-${variant}-500 border-slate-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200`
    "
    :checked="isChecked"
    :id="id"
    type="checkbox"
    :value="value"
    @change="updateInput"
  />
</template>

<script>
import { computed } from "vue";

export default {
  props: {
    falseValue: {
      default: false,
    },
    id: {
      required: true,
      type: String,
    },
    invalid: {
      required: true,
      type: Boolean,
    },
    label: {
      required: false,
      type: String,
    },
    modelValue: {
      required: true,
    },
    trueValue: {
      default: true,
    },
    value: {
      required: false,
      type: String,
    },
    variant: {
      required: true,
      type: String,
    },
  },

  setup(props, { emit }) {
    const isChecked = computed(() => {
      if (props.modelValue instanceof Array) {
        return props.modelValue.includes(props.value);
      }
      return props.modelValue === props.trueValue;
    });

    const updateInput = (event) => {
      const isChecked = event.target.checked;
      if (props.modelValue instanceof Array) {
        const newValue = [...props.modelValue];
        if (isChecked) {
          newValue.push(props.value);
        } else {
          newValue.splice(newValue.indexOf(props.value), 1);
        }
        emit("update:modelValue", newValue);
      } else {
        emit(
          "update:modelValue",
          isChecked ? props.trueValue : props.falseValue
        );
      }
    };

    return {
      isChecked,
      updateInput,
    };
  },
};
</script>
